<template>
  <div v-if="isVisible" class="popup-overlay">
    <div class="popup-content">
      <form @submit.prevent="checkPassword">
        <label for="password" style="color: black; padding-right: 10px;">Enter Password:</label>
        <input type="password" v-model="password" id="password" />
        <button type="submit">Submit</button>
      </form>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      password: '',
      error: ''
    }
  },
  methods: {
    checkPassword () {
      this.$emit('entered', this.password)
    }
  }
}
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}
</style>
